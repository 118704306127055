import React    from 'react';
import { Link } from 'gatsby';

import Wrapper       from '../components/Wrapper';
import Spacer        from '../components/Spacer';
import DefaultLayout from '../layouts/Default';

const NotFoundPage = (props) => (
  <DefaultLayout location={props.location}>
    <Wrapper>
      <Spacer/>
      <h1>Nicht gefunden</h1>
      <p>Diese Seite existiert leider nicht.</p>
      <Link to='/'>Zurück nach Hause.</Link>
      <Spacer/>
    </Wrapper>
  </DefaultLayout>
);

export default NotFoundPage
